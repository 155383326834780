import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LangIcon from "../assets/icons/langIcon";
import MenuIcon from "@mui/icons-material/Menu";
import { logo } from "../assets/index";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsModal from "./NotificationsModal/index";
import { logoutAction } from "../store/actions/auth/index";

export default function Navbar() {
  const { t, i18n } = useTranslation(["global"]);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isTop, setIsTop] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifcationEL, setNotificationEl] = useState(null);

  const isAuthenticated = useSelector((s) => s?.auth?.isAuthenticated);
  const totalUnRead = useSelector((s) => s.notifications.totalUnRead);

  const closeNotifications = useCallback(() => setNotificationEl(null), []);
  const handleClickNotifications = (e) => setNotificationEl(e.currentTarget);

  const isHomePage =
    location.pathname === "/" || location.pathname === "/wellness-center";
  const path = location.pathname;
  const open = Boolean(anchorEl);

  const tabs = [
    { name: "home", value: "/" },
    { name: "medical_treatments", value: "/treatment-form" },
    { name: "wellness_center", value: "/wellness-center" },
    { name: "hospitals", value: "/hospitals" },
    { name: "doctors", value: "/doctors" },
    { name: "healing", value: "/contact-us" },
    { name: "specializations", value: "/specializations" },
    { name: "contact", value: "/contact-us" },
    ...(isAuthenticated ? [{ name: "chat", value: "/chat" }] : []),
  ];

  const handleChange = (e, v) => navigate(v);

  const changeLanguage = useCallback(() => {
    let lang = i18n.language;
    let newLang = "en";
    if (lang === "en") {
      newLang = "ar";
    }

    localStorage.setItem("lng", newLang);
    window?.location?.reload();
  }, [i18n]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsTop(scrollTop === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <>
      {notifcationEL && (
        <NotificationsModal
          anchorEl={notifcationEL}
          handleClose={closeNotifications}
        />
      )}
      <Box
        sx={{
          maxWidth: "100vw",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: isHomePage && isTop ? "transparent" : "#0258B7",
          p: "0.5em 2em",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9,
          transition:
            "background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out",
          boxShadow: !isTop && "0px 10px 20px rgba(117, 117, 117, 0.18)",
        }}
      >
        <Box component={Link} to={"/"}>
          <img
            src={logo}
            alt=""
            style={{ height: "100%", cursor: "pointer" }}
          />
        </Box>

        {/*PC View*/}
        <Tabs
          value={path}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            display: { xs: "none", md: "flex" },
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "primary.main",
            },
            "& .MuiTab-root": {
              color: "background.paper",
              transition: "color 0.3s ease-in-out",
              "&:hover": {
                color: "primary.main",
              },
            },
            "& .MuiTabs-scrollButtons": {
              color: "background.paper",
            },
          }}
        >
          {tabs?.map((item, index) => (
            <Tab
              key={index}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "400" }}>
                    {t(item?.name)}
                  </Typography>
                </Box>
              }
              value={item?.value}
            />
          ))}
        </Tabs>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2em",
              "& Button, & a": {
                color: "background.paper",
                "&:hover": {
                  color: "primary.main",
                  bgcolor: "transparent",
                },
                transition:
                  "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
              },
            }}
          >
            <Button
              variant="text"
              onClick={changeLanguage}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
                "& > *": {
                  fill: "#FFF",
                },
              }}
            >
              <LangIcon />
              <Typography
                variant="body1"
                sx={{ textTransform: "uppercase", fontWeight: "400" }}
              >
                {i18n.language}
              </Typography>
            </Button>
            {isAuthenticated && (
              <Box sx={{ mx: 1 }}>
                <IconButton
                  sx={{
                    borderRadius: "6px",
                    p: 1,
                    color: "text.primary",
                  }}
                  onClick={handleClickNotifications}
                >
                  <Badge badgeContent={totalUnRead} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Box>
            )}
            <Button component={Link} to={"/track"}>
              <Typography variant="body1" sx={{ fontWeight: "400" }}>
                {t("track")}
              </Typography>
            </Button>
          </Box>
          {!isAuthenticated && (
            <>
              {/* <MainButton
              link={"/sign-up"}
              text={t("sign_up")}
              variant={"contained"}
              /> */}
              <Button component={Link} to={"/login"}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {t("login")}
                </Typography>
              </Button>
            </>
          )}
          {isAuthenticated && (
            <Button sx={{ mx: 2 }} color="secondary" onClick={handleLogout}>
              Logout
            </Button>
          )}
        </Box>
        {/*PC View*/}

        {/*Mobile View*/}
        <Button
          onClick={handleOpenMenu}
          size="small"
          sx={{
            height: "fit-content",
            width: "fit-content",
            p: "0.5em",
            minWidth: "fit-content",
            display: { xs: "inline-flex", md: "none" },
          }}
          aria-controls={open ? "header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MenuIcon sx={{ color: "primary.main" }} />
        </Button>

        <Menu
          disableScrollLock={true}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              p: "0.5em",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Tabs
            orientation="vertical"
            value={path}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "primary.main",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "primary.main",
              },
              "& .MuiTab-root": {
                color: "#002222",
                transition: "color 0.3s ease-in-out",
                "&:hover": {
                  color: "primary.main",
                },
              },
              "& .MuiTabs-scrollButtons": {
                color: "background.paper",
              },
            }}
          >
            {tabs?.map((e, i) => (
              <Tab
                key={i}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "700" }}>
                      {t(e?.name)}
                    </Typography>
                  </Box>
                }
                value={e?.value}
                aria-haspopup={e?.name === "industries"}
              />
            ))}
          </Tabs>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "1em",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1em",
                "& Button, & a": {
                  color: "#002222",
                  "&:hover": {
                    color: "primary.main",
                    bgcolor: "transparent",
                  },
                  transition:
                    "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                },
                p: "1em 1em 0 1em",
              }}
            >
              <Button
                variant="text"
                onClick={changeLanguage}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                  "& > *": {
                    fill: "#002222",
                  },
                  p: 0,
                }}
              >
                <LangIcon />
                <Typography
                  variant="body1"
                  sx={{ textTransform: "uppercase", fontWeight: "400" }}
                >
                  {i18n.language}
                </Typography>
              </Button>

              {!isAuthenticated && (
                <Button
                  component={Link}
                  to={"/login"}
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "start",
                    width: "fit-content",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "400" }}>
                    {t("login")}
                  </Typography>
                </Button>
              )}
            </Box>
            {/* {!isAuthenticated && (
            <MainButton link={"/sign-up"} text={t("Sign up")} />
            )} */}
            {isAuthenticated && (
              <Button sx={{ mx: 2 }} color="secondary" onClick={handleLogout}>
                Logout
              </Button>
            )}
          </Box>
        </Menu>
        {/*Mobile View*/}
      </Box>
    </>
  );
}
