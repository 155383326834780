import {
  GET_HOSPITALS,
  HOSPITALS_ERROR,
  HOSPITALS_LOADING,
  GET_HOSPITAL,
  HOSPITAL_ERROR,
  HOSPITAL_LOADING,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const singleHospitalInitial = {
  loading: false,
  data: {},
  error: null,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  singleHospital: singleHospitalInitial,
};

const hospitalsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(HOSPITALS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(HOSPITALS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_HOSPITALS, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.allPages = payload.allPages;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(HOSPITAL_LOADING, (state) => {
      state.singleHospital.loading = true;
    })
    .addCase(HOSPITAL_ERROR, (state, { payload }) => {
      state.singleHospital.error = payload;
      state.singleHospital.loading = false;
    })
    .addCase(GET_HOSPITAL, (state, { payload }) => {
      state.singleHospital.data =
        payload?.data?.length > 0 ? payload?.data[0] : payload?.data;
      state.singleHospital.loading = false;
    });
});

export default hospitalsReducer;
