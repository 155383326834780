import { BOT_APP_CONFIG } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  appId: null,
  user: null,
  apiKey: null,
  app: null,
  baseURL: "",
  identifier: "",
  senarioId: 1,
};

const botAppReducer = createReducer(initialState, (builder) => {
  builder.addCase(BOT_APP_CONFIG, (s, res) => {
    let payload = res.payload;
    s.appId = payload.appId;
    s.loading = false;
    s.apiKey = payload.apiKey;
    s.baseURL = payload.baseURL;
    s.app = payload.app;
    s.identifier = payload.identifier;
    s.senarioId = payload.senarioId;
  });
});

export default botAppReducer;
