import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function MainButton({ text, link, variant }) {
  return (
    <Button
      component={Link}
      to={link}
      variant={variant}
      sx={{
        bgcolor: variant === "contained" ? "primary.main" : "background.paper",
        width: "fit-content",
        p: "0.5em 1.5em",
      }}
    >
      <Typography
        variant="button"
        sx={{
          color: "#002222",
          fontWeight: "600",
          transition: "color 0.3s ease-in-out",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
}
