import { Box, Tabs, Typography, Tab, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Facebook, Instagram } from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import MainButton from "../components/MainButton";
import { Link } from "react-router-dom";
import SingleAnimation from "../components/Animations/SingleAnimation";

export default function Footer() {
  const { t } = useTranslation(["global"]);
  const location = useLocation();

  const path = location.pathname;

  const handleChange = (event, value) => {
    event.preventDefault();

    const element = document.getElementById(value);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const tabs = [
    { name: "home", value: "/" },
    { name: "explore", value: "explore" },
    { name: "why_us", value: "why-us" },
    { name: "hotels", value: "hotel" },
    { name: "flights", value: "flights" },
    { name: "transportation", value: "transportation" },
    { name: "reviews", value: "reviews" },
    { name: "contact", value: "contact-us" },
  ];

  const socials = [<Facebook />, <XIcon />, <Instagram />];

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(./images/BottomBannerBg.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          p: "4em",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2em",
            alignItems: "center",
          }}
        >
          <SingleAnimation delay={350} top>
            <Typography
              variant="h3"
              sx={{
                color: "background.paper",
                textAlign: "center",
              }}
            >
              {t(
                "Elevate your journey with our app's seamless travel and entertainment booking solutions!"
              )}
            </Typography>
          </SingleAnimation>

          <MainButton link={"/"} text={t("book_now")} variant={"contained"} />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "10%",
            transform: "translate(0, -50%)",
            display: { xs: "none", lg: "block" },
            height: "90%",
          }}
        >
          <img
            src="./images/headerLeft.svg"
            alt=""
            style={{ height: "100%" }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "10%",
            transform: "translate(0, -50%)",
            display: { xs: "none", lg: "block" },
            height: "90%",
          }}
        >
          <img
            src="./images/headerRight.svg"
            alt=""
            style={{ height: "100%" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "#023E80",
          p: "2em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            flexWrap: "wrap",
          }}
        >
          {socials?.map((social, index) => (
            <IconButton
              key={index}
              component={Link}
              to={"/"}
              sx={{
                borderRadius: "50%",
                bgcolor: "#DFEEFF",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                color: "#002222",
                transition: "0.5s all",
                "&:hover": {
                  transform: "scale(1.2)",
                  bgcolor: "#DFEEFF",
                },
              }}
            >
              {social}
            </IconButton>
          ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: "1em" }}>
          <Tabs
            value={path}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              margin: "0 auto",
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "primary.main",
              },
              "& .MuiTab-root": {
                color: "background.paper",
                transition: "color 0.3s ease-in-out",
                "&:hover": {
                  color: "primary.main",
                },
              },
              "& .MuiTabs-scrollButtons": {
                color: "white",
              },
            }}
          >
            {tabs?.map((item, index) => (
              <Tab
                key={index}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      {t(item?.name)}
                    </Typography>
                  </Box>
                }
                value={item?.value}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "#030C16",
          p: "0.5em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "500", color: "background.paper" }}
        >
          Copyright@Pentavalue2024. All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
}
