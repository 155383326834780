import { configureStore } from "@reduxjs/toolkit";
import { chatMediaSlice } from "./reducers/chatMediaSlice";
// import thunkMiddleware from "redux-thunk";
import {
  botApp,
  chatbot,
  hospitals,
  doctors,
  entertainment,
  transportation,
  app,
  auth,
  notifications,
  packages,
  specialization,
  tour,
  chats,
  singleChat,
  caseManagers,
} from "./reducers";
import entertainmentCategory from "./slices/EntertainmentCategory";

const store = configureStore({
  reducer: {
    botApp,
    chatbot,
    hospitals,
    doctors,
    entertainment,
    transportation,
    app,
    auth,
    notifications,
    packages,
    specialization,
    tour,
    chats,
    singleChat,
    caseManagers,
    entertainmentCategory,
    [chatMediaSlice.name]: chatMediaSlice.reducer,
  },
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(thunkMiddleware);
  // },
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
