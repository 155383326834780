import {
  GET_SPECIALIZATION,
  SPECIALIZATION_ERROR,
  SPECIALIZATION_LOADING,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const specializationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SPECIALIZATION_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(SPECIALIZATION_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_SPECIALIZATION, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    });
});

export default specializationReducer;
