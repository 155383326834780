import { loaderIcon } from "../../assets";
import "./loader.css";

const Loader = ({ height = "30px" }) => {
  return (
    <img className="loader" src={loaderIcon} alt="loaderIcon" height={height} />
  );
};

export default Loader;
