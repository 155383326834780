import {
  GET_PACKAGE,
  PACKAGE_ERROR,
  PACKAGE_LOADING,
  GET_PACKAGES,
  PACKAGES_ERROR,
  PACKAGES_LOADING,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const singlePackageInitial = {
  loading: false,
  data: {},
  error: null,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  singlePackage: singlePackageInitial,
};

const packageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PACKAGES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PACKAGES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_PACKAGES, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(PACKAGE_LOADING, (state) => {
      state.singlePackage.loading = true;
    })
    .addCase(PACKAGE_ERROR, (state, { payload }) => {
      state.singlePackage.error = payload;
      state.singlePackage.loading = false;
    })
    .addCase(GET_PACKAGE, (state, { payload }) => {
      state.singlePackage.data = payload?.data;
      state.singlePackage.loading = false;
    });
});

export default packageReducer;
