import {
  Box,
  Typography,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Grid,
  Avatar,
} from "@mui/material";
import { cutStrings } from "../../shared/cutStrings";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function NotificationItem({
  notification,
  isModal = false,
  appLang,
}) {
  let sender = notification?.sender;
  let senderName = notification?.sender?.username;

  let nav = useNavigate();
  const isEvent = notification?.typeId === 2;
  const taskId = notification?.typeId === 3;
  let entityId = notification?.entityId;
  const route = () => {
    const post = {
      postId: notification?.post?.id,
      pageId: notification?.post?.pageId,
    };

    if (isEvent) {
      return nav(`/events/${entityId}`);
    }

    if (taskId) {
      return nav(`/tasks/${entityId}`);
    }

    if (post?.postId) {
      return nav(
        `hub/community/pages/${post?.pageId}/timeline/${post?.postId}`
      );
    }
  };

  return (
    <MenuItem key={notification.id} sx={{ py: 1 }}>
      <ListItemAvatar>
        <Avatar
          onClick={""}
          src={sender?.image}
          alt={senderName}
          color={sender?.color}
          userId={sender?.id}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container button onClick={route}>
            <Grid item xs={9}>
              <Box
                sx={{ width: "100%" }}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box>
                  <Typography
                    sx={{ display: "inline", mr: 2 }}
                    component="span"
                    variant="body2"
                    color="text"
                  >
                    {senderName}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ display: "inline", mr: 2 }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {cutStrings(notification.title || "", 40)}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography
                sx={{ display: "inline", fontSize: ".7em" }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(notification?.createdAt).locale(appLang).fromNow()}
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </MenuItem>
  );
}
