import {
  GET_ENTERTAINMENTS,
  ENTERTAINMENTS_ERROR,
  ENTERTAINMENTS_LOADING,
  ENTERTAINMENTS_LOADING_HISTORY,
  ENTERTAINMENTS_ERROR_HISTORY,
  GET_ENTERTAINMENTS_HISTORY,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

// Function to sort the entertainment data
const sortEntertainmentData = (data) => {
  return data.slice().sort((a, b) => a.id - b.id);
};

const historyState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  history: historyState,
};

const entertainmentsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ENTERTAINMENTS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ENTERTAINMENTS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_ENTERTAINMENTS, (state, { payload }) => {
      const sortedData = sortEntertainmentData(payload?.data);
      state.data =
        payload?.thisPage === 1 ? sortedData : [...state?.data, ...sortedData];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(ENTERTAINMENTS_LOADING_HISTORY, (state) => {
      state.history.loading = true;
    })
    .addCase(ENTERTAINMENTS_ERROR_HISTORY, (state, { payload }) => {
      state.history.error = payload;
      state.history.loading = false;
    })
    .addCase(GET_ENTERTAINMENTS_HISTORY, (state, { payload }) => {
      const sortedData = sortEntertainmentData(payload?.data);
      state.history.data =
        payload?.thisPage === 1
          ? sortedData
          : [...state?.history?.data, ...sortedData];
      state.history.thisPage = payload.thisPage;
      state.history.nextPage = payload.nextPage;
      state.history.hasMore = payload.hasMore;
      state.history.count = payload.count;
      state.history.loading = false;
    });
});

export default entertainmentsReducer;
